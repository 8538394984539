export type AstraFiCard = {
  id: string;
  address_verified: boolean;
  card_company: string;
  card_type: AstraFiCard.Type;
  city: string;
  created: string;
  expiration_date: string;
  first_name: string;
  first_six_digits: string;
  interchange_category: string;
  last_four_digits: string;
  last_name: string;
  pull_enabled: boolean;
  push_enabled: boolean;
  removed: boolean;
  review_status: AstraFiCard.ReviewStatus;
  state: string;
  status: AstraFiCard.Status;
  street_line_1: string;
  street_line_2: string;
  user_id: string;
  zip_code: string;
};

export namespace AstraFiCard {
  export const Type = {
    DEBIT: 'debit',
    CREDIT: 'credit',
  } as const;

  export type Type = (typeof Type)[keyof typeof Type];

  /**
   * @link https://docs.astra.finance/reference/card-review-statuses#review-statuses
   */
  export const ReviewStatus = {
    /**
     * Card has been approved for use. Only Cards with this review status are authorized for use.
     */
    APPROVED: 'approved',
    /**
     * Client has the option to approve or reject the card via the Astra Dashboard. The User will be unable to use their card while the status is `flagged`.
     */
    FLAGGED: 'flagged',
    /**
     * Card has been rejected. The user will be unable to utilize this card.
     */
    REJECTED: 'rejected',
    /**
     * Card requires additional review by Astra.
     */
    UNREVIEWED: 'unreviewed',
  } as const;

  export type ReviewStatus = (typeof ReviewStatus)[keyof typeof ReviewStatus];

  /**
   * @link https://docs.astra.finance/reference/card-review-statuses#card-statuses
   */
  export const Status = {
    /**
     * Card has been approved for use. Only Cards with this review status are authorized for use.
     */
    APPROVED: 'approved',
    /**
     * Client has the option to approve or reject the card via the Astra Dashboard. The User will be unable to use their card while the status is `flagged`.
     */
    FLAGGED: 'flagged',
    /**
     * Card has been rejected. The user will be unable to utilize this card.
     */
    REJECTED: 'rejected',
    /**
     * Card requires additional review by Astra.
     */
    PENDING: 'pending',
    /**
     * Card has been removed by the user/client.
     */
    REMOVED: 'removed',
  } as const;

  export type Status = (typeof Status)[keyof typeof Status];
}

export namespace AstraFiUser {
  export type Status = (typeof Status)[keyof typeof Status];
  export const Status = {
    APPROVED: 'approved',
    PENDING: 'pending',
    RETRY: 'retry',
    DOCUMENT: 'document',
    SUSPENDED: 'suspended',
    REJECTED: 'rejected',
    CONVERTED_TO_USER: 'converted_to_user',
  } as const;

  export type KycType = (typeof KycType)[keyof typeof KycType];
  export const KycType = {
    RECEIVE_ONLY: 'receive-only',
    UNVERIFIED: 'unverified',
    VERIFIED: 'verified',
  } as const;
}

/**
 * @link https://docs.astra.finance/reference/routine-statuses
 */
export namespace AstraFiRoutine {
  export type Status = (typeof Status)[keyof typeof Status];

  export const Status = {
    REQUIRES_USER_VERIFICATION: 'requires_user_verification',
    PENDING_ACCOUNT_AUTHORIZATION: 'pending_account_authorization',
    USER_SUSPENDED: 'user_suspended',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    CANCELLED: 'cancelled',
    FAILED: 'failed',
    COMPLETED: 'completed',
  } as const;
}

/**
 * @link https://docs.astra.finance/reference/get_v1-transfers-id
 */
export namespace AstraFiTransfer {
  export type Status = (typeof Status)[keyof typeof Status];

  export const Status = {
    PENDING: 'pending',
    PROCESSED: 'processed',
    CANCELLED: 'cancelled',
    FAILED: 'failed',
  } as const;
}

export namespace AstraFiWebhook {
  export type EventType = (typeof EventType)[keyof typeof EventType];
  export const EventType = {
    USER_INTENT_UPDATED: 'user_intent_updated',
    USER_UPDATED: 'user_updated',
    ROUTINE_UPDATED: 'routine_updated',
    TRANSFER_CREATED: 'transfer_created',
    TRANSFER_UPDATED: 'transfer_updated',
  };
}
