import { Analytics, setUserId, setUserProperties } from 'firebase/analytics';
import { User } from 'firebase/auth';

export type AnalyticsUserProperties = {
  email: string | null;
  name: string | null;
};

export const analytics = {
  registerUser: (analytics: Analytics, user: User) => {
    setUserId(analytics, user.uid);
    setUserProperties(analytics, {
      email: user.email,
      name: user.displayName,
    } satisfies AnalyticsUserProperties);
  },
  unregisterUser: (analytics: Analytics) => {
    setUserId(analytics, null);
    setUserProperties(analytics, {
      email: null,
      name: null,
    } satisfies AnalyticsUserProperties);
  },
} as const;
