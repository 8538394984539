import {
  PaymentLink,
  PaymentStatus,
  Price,
  RecurringInterval,
  Subscription,
} from '@spherelabs/common';
import { isAfter, isBefore, parseISO } from 'date-fns';

export const getTruncatedAddress = (
  publicKey: string | undefined,
  amount: number = 6,
) =>
  !publicKey
    ? ''
    : publicKey.slice(0, amount) + '...' + publicKey.slice(-amount);

// TODO: Fix `getTruncatedString` algorithm
/**
 * @deprecated Avoid using this function! The algorithm is flawed and may result in duplicated content depending on the length of the string.
 */
export const getTruncatedString = (value: string | undefined, length = 6) =>
  !value ? '' : `${value.slice(0, length)}...${value.slice(-length)}`;

export const getTruncatedLink = (link: string | undefined) => {
  if (link === undefined) return '';
  const linkArray = link.split('/');
  const truncatedLink = linkArray[linkArray.length - 1];
  return `....${linkArray[2].slice(0, 5)}..../${truncatedLink.slice(0, 6)}`;
};

export const getPaymentStatusStyle = (status: PaymentStatus): string => {
  switch (status) {
    case PaymentStatus.SUCCEEDED:
      return 'bg-[#D2F6DC] text-[#075E45]';
    case PaymentStatus.REFUNDED:
      return 'bg-[#EDEDED] text-[#6C6C6C]';
    case PaymentStatus.FAILED:
      return 'bg-[#FADCD9] text-[#A1160A]';
    default:
      return 'bg-[#FFE4BC] text-[#A83D00]';
  }
};

export const getSubscriptionBillAmount = (subscription: Subscription) => {
  return subscription.subscriptionItems.reduce((acc, cur) => {
    return acc + cur.price.unitAmountDecimal * cur.quantity;
  }, 0);
};

export const getSubscriptionBillDuration = (price: Price) => {
  switch (price.recurring.interval) {
    case RecurringInterval.MONTH:
      if (price.recurring.intervalCount === 1) {
        return 'per month';
      }
      return `Every ${price.recurring.intervalCount} Months`;
    case RecurringInterval.YEAR:
      if (price.recurring.intervalCount === 1) {
        return 'per year';
      }
      return `Every ${price.recurring.intervalCount} Years`;
    case RecurringInterval.WEEK:
      if (price.recurring.intervalCount === 1) {
        return 'per week';
      }
      return `Every ${price.recurring.intervalCount} Weeks`;
    case RecurringInterval.DAY:
      if (price.recurring.intervalCount === 1) {
        return 'per day';
      }
      return `Every ${price.recurring.intervalCount} Days`;
    case RecurringInterval.MIN:
      if (price.recurring.intervalCount === 1) {
        return 'per minute';
      }
      return `Every ${price.recurring.intervalCount} Minutes`;
  }
};

export const getPaymentLinkStatus = (paymentLink: PaymentLink): boolean => {
  const today = new Date();
  const end = paymentLink.end ? parseISO(paymentLink.end) : null;
  const start = paymentLink.start ? parseISO(paymentLink.start) : null;
  const isAfterStart = (start && isAfter(today, start)) || !start;
  const isBeforeEnd = (end && isBefore(today, end)) || !end;

  return isAfterStart && isBeforeEnd;
};

/**
 * getTruncatedFilename
 * @param filename - The filename to be truncated.
 * @param lengthThreshold - (optional) The minimum length of filename to truncate.
 */
export function getTruncatedFilename(filename: string, lengthThreshold = 20) {
  if (filename.length < lengthThreshold) return filename;

  const sideLength = Math.floor(lengthThreshold / 2.5);

  const filenamePieces = filename.split('.');

  const extension = filenamePieces.pop();
  const filenameBeforeExtension = filenamePieces
    .slice(0, filenamePieces.length)
    .join('.');

  const firstLetters = filenameBeforeExtension.substring(0, sideLength);
  const lastLetters = filenameBeforeExtension.substring(
    filenameBeforeExtension.length - sideLength,
  );

  return `${firstLetters}…${lastLetters}.${extension}`;
}

export * from './clsx';
export * from './cn';
export * from './createFirebaseApp';
export * from './currencyFormatters';
export { default as dayjs } from './dayjs';
export * from './downloadText';
export * from './lineItem';
export * from './price';
export * from './signOut';
export * from './translator';
