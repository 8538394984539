import Decimal from 'decimal.js';

export const formatToHumanReadableAmount = ({
  rawAmount,
  decimals,
}: {
  rawAmount: string;
  decimals: number;
}) => {
  const humanReadableAmount = new Decimal(rawAmount).dividedBy(10 ** decimals);
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: decimals,
  });

  return formatter.format(humanReadableAmount.toNumber());
};

export const format2Dp = (num: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return formatter.format(num);
};

export const formatNDp = (num: number, n = 2) => {
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: n,
    minimumFractionDigits: n,
  });
  return formatter.format(num);
};

export const format0Dp = (num: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  return formatter.format(num);
};

export const formatUsd = (usdcRawAmount: string) => {
  const humanReadableAmount = new Decimal(usdcRawAmount).div(10 ** 6);
  return format2Dp(humanReadableAmount.toNumber());
};

export const formatUsd2Dp = (value: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
};

export const formatUsd0Dp = (value: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(value);
};
