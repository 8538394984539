export default function downloadText(fileName: string, textToDownload: string) {
  const anchorElement = document.createElement('a');
  anchorElement.download = fileName;
  anchorElement.href = textToDownload;

  document.body.appendChild(anchorElement);

  anchorElement.click();

  anchorElement.parentNode?.removeChild(anchorElement);
}

export function flattenObject(obj: any) {
  const result: Record<string, any> = {};

  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      result[key] = JSON.stringify(obj[key]);
    } else {
      result[key] = obj[key];
    }
  }

  return result;
}

export function downLoadCSV({
  fileName,
  csv,
}: {
  fileName: string;
  csv: string;
}) {
  const blob = new Blob([csv], {
    type: 'text/csv;charset=utf-8;',
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${fileName}.csv`);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function downloadFromUrl({
  fileName,
  url,
}: {
  fileName: string;
  url: string;
}) {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
