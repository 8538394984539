import { getApps, initializeApp } from 'firebase/app';

const customerAppKey = 'customerApp';

/**
 * @deprecated use reactfirebase hooks to manage firebase app. Merchant login has been migrated,
 * customer login is in progress.
 */
export const createFirebaseCustomerApp = () => {
  // Note the defaults are for staging because env vars suck. TODO: pass these in as env vars.
  const clientCredentials = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  };

  const loadedApps = getApps();
  const customerApp = loadedApps.find((app) => app.name === customerAppKey);
  if (!customerApp) {
    const customerApp = initializeApp(clientCredentials, customerAppKey);
    return { customerApp };
  }

  return { customerApp };
};
