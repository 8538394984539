import {
  Coupon,
  CustomField,
  DiscordConstraint,
  DiscordUserGuildRoles,
  Network,
  PaymentLinkConstraint,
  Phase as PaymentLinkPhase,
  PriceType,
  RecurringInterval,
  RecurringType,
  RecurringUsageAggregation,
  RecurringUsageType,
  Token,
} from '@spherelabs/common';
import Decimal from 'decimal.js';

import { usePaymentLinkFormik } from '../hooks';

export type PaymentLinkStore = Partial<FetchedPaymentLinkState> & {
  setCustomFields: (customFields: CustomField[]) => void;

  setPaymentLinkState: (paymentLinkState: FetchedPaymentLinkState) => void;

  transactionStatus: TransactionStatus;
  setTxId: (txId: string | undefined) => void;
  setError: (error: { error: Error; details?: string } | undefined) => void;

  formik: ReturnType<typeof usePaymentLinkFormik>;
  setFormik: (formik: ReturnType<typeof usePaymentLinkFormik>) => void;

  apiOrigin: string | undefined;
  setApiOrigin: (apiOrigin: string) => void;

  frontendUrl: string;
  setFrontendUrl: (frontendUrl: string) => void;

  isPosMode: boolean;
  setIsPosMode: (isPosMode: boolean) => void;

  restrictCurrency: boolean;
  setrestrictCurrency: (restrictCurrency: boolean) => void;

  isPreview: boolean;
  setIsPreview: (isPreview: boolean) => void;

  discount: Decimal | undefined;
  setDiscount: (discount: Decimal | undefined) => void;

  percentageDiscount: Decimal | undefined;
  setPercentageDiscount: (percentageDiscount: Decimal | undefined) => void;

  paymentReference: string | undefined;
  setPaymentReference: (paymentReference: string | undefined) => void;

  isTransactionDialogOpen: boolean;
  setIsTransactionDialogOpen: (isTransactionDialogOpen: boolean) => void;

  approveErc20SpendToken: Token | null;
  setApproveErc20SpendToken: (approveErc20SpendParams: Token | null) => void;

  skipPreflight: boolean;
  setSkipPreflight: (skipPreflight: boolean) => void;

  currentDate: Date;
  setCurrentDate: (currentDate: Date) => void;

  setLineItems: (lineItems: LineItem[]) => void;

  isDonationMode: boolean;
};

export type OnPaymentConfirmed = (
  paymentLinkState: PaymentLinkStore,
  subscriptionOrPaymentId?: string,
) => void;

export interface FetchedPaymentLinkState {
  hasLoaded: boolean;
  paymentLinkId: string;
  application: Application | null;
  creditCardMerchantId: string | null;
  lineItems: LineItem[];
  successUrl: string | null;
  failUrl: string | null;
  requiresName: boolean;
  requiresEmail: boolean;
  requiresShippingDetails: boolean;
  taxRatePercentageDecimal: number;
  clientSecret: string | null;
  stripeAccountId: string | null;
  coupon: Coupon | null;
  discordConstraints: DiscordConstraint[] | null;
  globalConstraint: PaymentLinkConstraint | null;
  networks: Network[];
  paymentLinkPhases: PaymentLinkPhase[];
  discordUserGuildRoles: DiscordUserGuildRoles | null;
  programId: string;
  hasCustomUnitAmount: boolean;
  customFields: CustomField[];
  humanReadableCustomUnitAmountMaximum: number | null;
  humanReadableCustomUnitAmountMinimum: number | null;
  humanReadableCustomUnitAmountPresets: number[] | null;
  shouldHidePaymentLinkPhaseSupply: boolean;
  shouldHidePriceImpactWarning: boolean;
  access: {
    payout: boolean;
    creditCard: boolean;
  };
  defaultDisplayedCurrency: string | null;
  description: string;
  disableSwaps: boolean;
}

interface Application {
  nickname: string | undefined;
  image: string | undefined;
}

export interface LineItem {
  id: string;
  quantity: number;
  quantityMutable: boolean;
  quantityLabel: string | undefined;
  price: Price;
  amountTotal: Decimal | null;
  description: string | null;
}

export interface Tier {
  upTo: number | string;
  unitAmount: string;
  flatAmount: string;
}

export enum TierType {
  GRADUATED = 'graduated',
  VOLUME = 'volume',
}

export interface Price {
  id: string;
  type: PriceType;
  currency: string;
  tierType: TierType;
  unitAmount: Decimal | null;
  product: Product;
  tiers: Tier[];
  currencyOptions: Price[];
  network: Network;
  recurring: {
    type: RecurringType;
    interval: RecurringInterval;
    intervalCount: number;
    defaultLength: number;
    usageAggregation: RecurringUsageAggregation;
    usageType: RecurringUsageType;
    expectedUsagePerInterval: number;
  };
}

interface Product {
  images: string[];
  name: string;
  description: string;
}

interface TransactionStatus {
  txId: string | undefined;
  error: { error: Error; details?: string } | undefined;
}
