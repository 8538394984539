import { getAnalytics } from 'firebase/analytics';
import { FirebaseApp } from 'firebase/app';
import { getAuth, signOut as signOutFirebase } from 'firebase/auth';

import { analytics } from './analytics';
import { createFirebaseCustomerApp } from './createFirebaseApp';

export async function signOut(app: FirebaseApp) {
  const auth = getAuth(app);
  analytics.unregisterUser(getAnalytics(app));
  return signOutFirebase(auth);
}

export async function signOutCustomerApp() {
  const { customerApp } = createFirebaseCustomerApp();
  const auth = getAuth(customerApp);
  analytics.unregisterUser(getAnalytics(customerApp));
  return signOutFirebase(auth);
}
