import { BillingScheme, Price, Token } from '@spherelabs/common';

import { formatToHumanReadableAmount } from './currencyFormatters';

export const getHumanReadablePrice = ({
  price,
  tokenInfo,
}: {
  price: Price;
  tokenInfo: Token;
}): string => {
  if (price.unitAmount === null) {
    return 'Customer chooses';
  }

  switch (price.billingScheme) {
    case BillingScheme.PER_UNIT:
      return formatToHumanReadableAmount({
        rawAmount: price.unitAmount,
        decimals: tokenInfo.decimals,
      });
    case BillingScheme.TIERED:
      return `Starting at ${formatToHumanReadableAmount({
        rawAmount: String(
          Number(price.tiers[0].unitAmount) + Number(price.tiers[0].flatAmount),
        ),
        decimals: tokenInfo.decimals,
      })}`;
    default:
      return '0';
  }
};
