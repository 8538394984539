import { LineItem, Token } from '@spherelabs/common';
import { calculateTotalPaymentLinkRawPrice } from '@spherelabs/react-private/components/css/SpherePayment/calculateTotalPaymentLinkRawPrice';

export const calcSubtotalFromLineItems = (
  lineItems: LineItem[],
  tokens: Token[] | undefined,
) => {
  const quantities = lineItems.map((item) => {
    if (item.quantityMutable) {
      return '1';
    }
    return item.quantity.toString();
  });
  const subTotal = calculateTotalPaymentLinkRawPrice(
    lineItems as any, // method takes in a slightly different type than what we have in the lineItems array
    quantities,
    lineItems?.[0]?.price?.network as any, // network will not be undefined as price is checked in calling function,
    tokens,
  );

  return subTotal;
};
