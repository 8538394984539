import { Network, Token } from '@spherelabs/common';
import Decimal from 'decimal.js';

import {
  LineItem,
  Price,
  Tier,
  TierType,
} from '../../../types/PaymentLinkStore';

function calculateGraduatedTiersRawPrice(
  tiers: Tier[] | undefined,
  units: number,
): Decimal {
  if (tiers === undefined) return new Decimal(0);
  let amount = new Decimal(0);
  let prevTierUpTo = 0;

  for (const tier of tiers) {
    if (units === 0) break;

    const flatAmount = new Decimal(tier.flatAmount);
    const upTo = tier.upTo === 'inf' ? Infinity : Number(tier.upTo);
    const billableUnits = Math.min(units, upTo - prevTierUpTo);

    const unitAmount = new Decimal(tier.unitAmount).mul(billableUnits);

    if (billableUnits > 0) {
      amount = amount.add(flatAmount);
    }

    amount = amount.add(unitAmount);
    units -= billableUnits;
    prevTierUpTo = upTo;
  }
  return amount;
}

function calculateVolumeTiersRawPrice(
  tiers: Tier[] | undefined,
  units: number,
): Decimal {
  if (tiers === undefined || tiers.length === 0) return new Decimal(0);
  let tierToCharge = tiers[0];
  for (let i = 0; i < tiers.length; i++) {
    const curTier = tiers[i];
    const upTo = curTier.upTo === 'inf' ? Infinity : Number(curTier.upTo);
    if (units > upTo) {
      if (upTo === Infinity) {
        tierToCharge = curTier;
      } else {
        tierToCharge = tiers[i + 1];
      }
    }
  }
  const unitAmount = new Decimal(tierToCharge.unitAmount).mul(units);
  const flatAmount = new Decimal(tierToCharge.flatAmount);
  return unitAmount.add(flatAmount);
}

function calculateTierBillingAmountFromUnits(
  price: Price,
  units: number,
): Decimal {
  const tierType = price.tierType;
  const tiers = price.tiers;
  return tierType === TierType.GRADUATED
    ? calculateGraduatedTiersRawPrice(tiers, units)
    : calculateVolumeTiersRawPrice(tiers, units);
}

export function calculateTotalPaymentLinkRawPrice(
  lineItems: LineItem[] | undefined,
  quantities: string[] | undefined,
  selectedNetwork: Network | null,
  tokens: Token[] | undefined,
) {
  const rawPaymentAmount = lineItems?.reduce<string | undefined>(
    (total, lineItem, i) => {
      const price = [lineItem.price, ...lineItem.price.currencyOptions].find(
        (price) => price.network === selectedNetwork,
      );
      if (
        price === undefined ||
        total === undefined ||
        quantities === undefined ||
        tokens === undefined
      ) {
        return undefined;
      }

      if (lineItem.amountTotal) {
        // convert api v2 decimal response to raw
        const token = tokens.find(
          (token) =>
            token.network === price.network && token.address === price.currency,
        );

        if (!token) {
          console.error('Token not found, cannot calculate raw price');
          return undefined;
        }

        return lineItem.amountTotal
          .times(10 ** token.decimals)
          .add(new Decimal(total))
          .toFixed();
      }
      if (price.unitAmount !== null) {
        try {
          const quantity = new Decimal(quantities[i]);
          const unitAmountTimesQuantity = new Decimal(price.unitAmount).times(
            quantity,
          );
          const tierAmount = calculateTierBillingAmountFromUnits(
            price,
            Number(quantity),
          );
          const amount = unitAmountTimesQuantity.add(tierAmount);
          return amount.add(new Decimal(total)).toFixed();
        } catch {
          return undefined;
        }
      }
    },
    '0',
  );

  const firstPrice = lineItems?.[0]?.price;
  const currency = !firstPrice
    ? undefined
    : [firstPrice, ...firstPrice.currencyOptions].find(
        (price) => price.network === selectedNetwork,
      )?.currency;

  return { rawPaymentAmount, currency };
}
